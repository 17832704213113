import React, { useState, useEffect } from 'react';
import { IUser } from '@/shared';
import { Card } from '@/shared';
import {
  Top,
  Title,
  CollapseButton,
} from './styles';
import {
  Button,
  Box,
} from '@chakra-ui/react';
import {
  TaskUserReviews,
  ProjectUserReviews,
} from '@/entities/projects';
import {
  getTasksByOwner,
  getTasksByContractor,
  getProjectsByOwner,
  getProjectsByContractor,
} from '@/entities/projects';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { Link } from 'react-router-dom';

export interface IProjectActivityProps {
  user: IUser;
}

export const ProjectActivity: React.FC<IProjectActivityProps> = ({ user }) => {
  const storageUser = JSON.parse(localStorage.getItem('user'));
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [tasksIsLoading, setTasksIsLoading] = useState(true);
  const [projectsIsLoading, setProjectsIsLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    if (user?.role === 'Manager') {
      getProjectsByOwner(user?.id)
        .then((resOwner: any) => {
          getProjectsByContractor(user?.id)
            .then((resContractor: any) => {
              setProjects([...resOwner.data, ...resContractor.data]);
              setProjectsIsLoading(false);
            })
            .catch((error: any) => {
              console.log(error);
            });
        })
        .catch((error: any) => {
          console.log(error);
        });
      
      getTasksByOwner(user?.id)
        .then((resOwner: any) => {
          getTasksByContractor(user?.id)
            .then((resContractor: any) => {
              setTasks([...resOwner.data, ...resContractor.data]);
              setTasksIsLoading(false);
            })
            .catch((error: any) => {
              console.log(error);
            });
        })
        .catch((error: any) => {
          console.log(error);
        });
    }

    else {
      getProjectsByContractor(user?.id)
        .then((res: any) => {
          setProjects([...projects, ...res.data]);
          setProjectsIsLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
        });
      
      getTasksByContractor(user?.id)
        .then((res: any) => {
          setTasks([...tasks, ...res.data]);
          setTasksIsLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [])

  return (
    <Card>
      <Top>
        <Title>Участие в проектах и задачах</Title>
        {storageUser?.id === user?.id &&
          <Link
            to={
              (user.role === 'Manager') ?
              '/dashboard-manager-projects' :
              '/dashboard-employee-projects'
            }
          >
            <Button
              type='button'
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: 'var(--hover-purple)' }}
              color='#fff'
            >
              Найти проект
            </Button>
          </Link>
        }
      </Top>

      {(tasksIsLoading || projectsIsLoading) &&
        <Box py={6} px={4} background="var(--main-white)" borderRadius="lg">
          Загрузка...
        </Box>
      }

      {
        tasks.length > 0 &&
        tasks
        .slice(0, isCollapsed ? 4 : tasks.length)
        .map((task) =>
          <TaskUserReviews user={user} task={task} />
        )
      }

      {
        projects.length > 0 &&
        projects
        .slice(0, isCollapsed ? (4 - tasks.length < 0 ? 0 : 4 - tasks.length) : projects.length)
        .map((project) =>
          <ProjectUserReviews user={user} project={project} />
        )
      }

      {projects.length + tasks.length > 4 &&
        <Box py={2}>
          <CollapseButton
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <>Показать все</> : <>Скрыть</>}
            <CollapseIcon
              style={{
                  transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
              }}
              width={10}
              height={5}
            />
          </CollapseButton>
        </Box>
      }
    </Card>
  );
}
