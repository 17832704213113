import React, { useEffect, useRef, useState } from 'react';
import {
  bounds,
  pack,
} from './utils';
import * as d3 from 'd3';
// @ts-ignore
import tip from 'd3-tip';
import './style.css';
import { num_word } from '@/shared';
import analyticsState from '@/pages/Analytics/store/analyticsState';
import { observer } from 'mobx-react-lite';

export interface IBubbleChartProps {
  data: any;
  width?: number;
  height?: number;
}

export const BubbleChart: React.FC<IBubbleChartProps> = observer(({ data,  width = Infinity, height = Infinity }) => {
  const gap = 5;
  const name = (d: any) => {
    if (d.data) {
      return d.data.title;
    }

    return d.title;
  }
  const value = (d: any) => {
    if (d.data) {
      return d.data.count;
    }

    return d.count;
  }
  const names = (d: any) => name(d).split(/\s+/g);
  const format = d3.format(',d');

  // const color = d3.scaleOrdinal(d3.schemeTableau10);
  // You can make it change according to circle sizes
  const color = (d: any, data: any) => {
    if (Number(d3.max(data, value)) / 1.5 < value(d)) {
      return '#1538AF';
    } else if (Number(d3.max(data, value)) / 2 < value(d)) {
      return '#466DF2';
    } else {
      return '#8FA8FF';
    }
  };

  const svgRef = useRef(null);

  useEffect(() => {
    d3.select(svgRef.current).selectAll('*').remove();
    const sizeScale = d3.scaleLinear()
    // @ts-ignore
      .domain([d3.min(data, value), d3.max(data, value)])
      .range([40, 80]);

    const processedData = data.map((d: any) => ({
      title: d.title,
      count: d.count,
      r: sizeScale(d.count) + 20,
    }));

    const circles = pack(processedData.sort((a: any, b: any) => a.count < b.count), height, width);

    const svg = d3.select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr('text-anchor', 'middle')
      .attr("viewBox", bounds(circles, data.length < 10 ? 100 : 1).join());

    const toolTip = tip()
      .attr('class', 'd3-tip')
      .offset([-5, 0])
      .html((e: any) => {
        const d = e.currentTarget.__data__;
        return `
          <div class="d3-tip-title">${d.title}</div>
          <div>${format(d.count)} ${num_word(d.count, ['человек', 'человека', 'человек'])}</div>
        `
      });

    svg.call(toolTip)

    const node = svg.append('g')
      .selectAll()
      .data(circles)
      .join('g')
      .attr('transform', (d: any) => `translate(${d.x}, ${d.y})`)
      .on('mouseover', toolTip.show)
      .on('mouseout', toolTip.hide);

    node.selectAll('g')

    node.append('circle')
      .attr('fill-opacity', 0.7)
      .attr('fill', (d: any) => color(d, processedData))
      .attr('r', (d: any) => d.r - gap);

    // Add a label.
    const text = node.append('text')
      .style('fill', 'var(--main-white)')
      .style('text-anchor', 'middle')
      .style('dominant-baseline', 'middle')
      .style('font-size', (d: any) => {
        const circumference = 2 * Math.PI * d.r;
        const estimatedCharacters = circumference / 2.5; // Adjust this factor as needed
        const wordLengths = names(d).map((item: any) => item.length);
        const maxLength = wordLengths.reduce((accumulator: any, currentValue: any) => {
            return Math.max(accumulator, currentValue);
        }, wordLengths[0]);

        const fontSize = Math.min(45, estimatedCharacters / maxLength);
        return `${fontSize}px`;
      })
      .style('font-weight', 600)
      .style('font-family', 'monospace');

    text.selectAll()
      .data((d: any) => names(d))
      .join('tspan')
      .attr('x', 0)
      .attr('y', (d: any, i: any, nodes: any) => `${i - nodes.length / 2 + 0.5}em`)
      .text((d: any) => d);

    const x = (d: any) => (d.r - gap) * Math.cos(Math.PI / 4);
    const y = (d: any) => (d.r - gap) * Math.sin(Math.PI / 4);

    // Create a new g element for each leaf node and position it at the top of the main circle.
    const countCircle = node.append('g')
      .attr('transform', (d: any) => `translate(${x(d)}, -${y(d)})`);
    
    // Append a circle element to the g element.
    countCircle.append('circle')
      .attr('r', 20)
      .attr('fill', 'var(--main-white)')
      .attr('stroke', 'var(--addable-gray)')
      .attr('stroke-width', '4px');

    // Append a text element to the g element and display the count.
    countCircle.append('text')
      .attr('x', 0)
      .attr('y', 0)
      .style('font-size', '18px')
      .style('font-family', 'sans-serif')
      .style('text-anchor', 'middle')
      .style('dominant-baseline', 'middle') // Center the text vertically
      .text((d: any) => format(value(d)));
  }, [JSON.stringify(data)]);

  return (
    <svg ref={svgRef}></svg>
  );
});
