import React, { useState, useEffect } from 'react';
import { Card, IUser } from '@/shared';
import {
    Head,
    H6,
    Skills,
} from './styles';
import { Filters } from '@/features/common';
import EditIcon from '@/assets/icons/edit.svg';
import { Button, useDisclosure } from '@chakra-ui/react';
import { Skill } from '@/shared';
import { EditSkillsPopup } from '@/features/employee';
import { getSkills, updateSkillConfirmation } from '@/features/employee';
import { styled, createGlobalStyle } from 'styled-components';

export interface MySkillsProps {
    user: IUser;
}

const GlobalStyle = createGlobalStyle`
    .custom-skill-container > div {
        background: none !important;
        border: none !important;
    }
`;

export const MySkills: React.FC<MySkillsProps> = ({ user }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [skillsType, setSkillsType] = useState('Hard');
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        getSkills(user?.id)
            .then((res: any) => {
                if (res?.status === 200) {
                    setSkills(res.data.skill);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [user?.id]);


    const options = [
        {
            id: 1,
            name: 'Hard Skills',
            onClick: () => {
                setSkillsType('Hard');
            }
        },
        {
            id: 2,
            name: 'Soft Skills',
            onClick: () => {
                setSkillsType('Soft');
            }
        },
    ];
    const handleSkillClick = (skill: any) => {
        const newConfirmedStatus = !skill.confirmed; // Инвертируем статус
        updateSkillConfirmation(skill.id, newConfirmedStatus) // Отправляем запрос на сервер
            .then(() => {
                // Обновляем локальное состояние после успешного обновления на сервере
                setSkills(skills.map(s =>
                    s.id === skill.id ? { ...s, confirmed: newConfirmedStatus } : s
                ));
            })
            .catch((error) => {
                console.log('Ошибка при обновлении статуса навыка:', error);
            });
    }

    return (
        <>
            <GlobalStyle />
                <Card>
                    <Head>
                        <h3>Мои навыки</h3>
                        <Button
                            onClick={onOpen}
                            border='1px var(--addable-gray) solid'
                            borderRadius='8px'
                            bg='var(--main-white)'
                            leftIcon={<EditIcon color='var(--main-purple)' />}
                        >
                            <H6>Редактировать</H6>
                        </Button>
                    </Head>
                    <Filters options={options}/>
                    <Skills>
                        {skills && skills.map((skill: any) => (
                            <div className="custom-skill-container"
                                key={skill.id}
                                style={{
                                    backgroundColor: skill.confirmed ? 'var(--main-purple)' : 'gray',
                                    color: 'white',
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                    margin: '5px 0',
                                    transition: 'background-color 0.3s',
                                    textDecoration: 'none',
                                    outline: 'none',
                                    border: 'none'
                                }}
                                onClick={() => handleSkillClick(skill)}
                                onMouseOver={(e) => {
                                    if (skill.confirmed) {
                                        e.currentTarget.style.backgroundColor = 'var(--main-purple)';
                                    }
                                }}
                                onMouseOut={(e) => {
                                    if (skill.confirmed) {
                                        e.currentTarget.style.backgroundColor = 'var(--main-purple)';
                                    }
                                }}
                            >
                                {skill.type === skillsType &&
                                    <Skill
                                        key={skill.id}
                                        title={skill.name}
                                        confirmed={skill.confirmed}
                                    />
                                }
                            </div>
                        ))}
                    </Skills>

                </Card>

            <EditSkillsPopup isOpen={isOpen} onClose={onClose}/>
        </>
    )
}
