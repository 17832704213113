import React from 'react';

export interface ICrossDeleteProps {}

export const CrossDelete: React.FC<ICrossDeleteProps> = () => {
  return (
    <div
      style={{ cursor: 'pointer' }}
    >
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_56_6234)">
          <path d="M1 1L11 11" stroke="#F83C3C" stroke-width="2" stroke-linecap="round"/>
          <path d="M1 11L11 0.999999" stroke="#F83C3C" stroke-width="2" stroke-linecap="round"/>
        </g>
        <defs>
          <clipPath id="clip0_56_6234">
            <rect width="12" height="12" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
