import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { ProjectDrawer } from '@/widgets';
import { IUser, TProject } from '@/shared';
import { Skill } from '@/shared';
import { Reviews } from '@/entities/projects';
import { SkillsList } from '@/widgets';
import { num_word } from '@/shared';
import { getReviews } from '@/entities/projects';
import projectState from '@/widgets/ProjectDrawer/store/projectState';

export interface IProjectUserReviewsProps {
  user: IUser;
  project: TProject;
}

export const ProjectUserReviews: React.FC<IProjectUserReviewsProps> = ({ user, project }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [reviews, setReviews] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleOpen() {
    projectState.setSelectedProject(project);
    onOpen();
  }

  useEffect(() => {
    if (!isCollapsed && !isLoaded) {
      getReviews(project.id, user.id)
        .then((res) => {
          setReviews(res.data);

          setIsLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isCollapsed]);

  const userRoles = project.expected_roles ?
    project?.expected_roles
    .filter((role) => role.user ? role.user.id === user.id : false)
    .map((role) => ({ id: role.id, name: role.title })) :
    [];

  const userTasks = project?.tasks
    .filter((task) => task.contractors.map((c) => c.id).includes(user.id))
    .map((task) => task.title);

  console.log(JSON.stringify(project.tasks));

  return (
    <Box py={2} px={4} background="var(--main-white)" borderRadius="lg">
      <Flex align="center" gap={4}>
        <Button background="inherit" onClick={handleOpen}>
          <h5>{project.title}</h5>
        </Button>

        <Box opacity={0.5}>
          {userTasks.length} {num_word(userTasks.length, ['задача', 'задачи', 'задач'])}
        </Box>

        {(user.id === project.owner.id) ? 
          <Skill title="Руководитель" /> :
          <SkillsList skills={userRoles} />
        }

        <Button
          onClick={() => setIsCollapsed(!isCollapsed)}
          background="none"
          marginLeft="auto"
        >
          <CollapseIcon
            style={{
              transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
            }}
          />
        </Button>
      </Flex>

      {!isCollapsed &&
        <>
          <Stack pb={4}>
            <h6>Задачи</h6>
            {userTasks.map((task) => 
              <Box p={2} boxShadow="base" borderRadius="lg"><h5>{task}</h5></Box>
            )}
          </Stack>

          {!isLoaded ?
            <Box>Загрузка...</Box> :
            <>
              {reviews.length > 0 ? 
                <Reviews reviews={reviews} /> :
                <Box>Оценок нет</Box>
              }
            </>
          }
        </>
      }
      <ProjectDrawer isOpen={isOpen} onClose={onClose}/>
    </Box>
  );
}
