import axios from 'axios';
import { IUser } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));
const userId = JSON.parse(localStorage.getItem('user'))?.id;

export const getHrEmployees = async (companyName: string) => {
    const res = await axios({
        url: `${API}/users/?role__in=Super HR manager,HR manager&company__name=${companyName}` ,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const getEmployees = async (companyId: number) => {
    const res = await axios({
        url: `${API}/users/?company__id=${companyId}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const addEmployee = async (user: IUser) => {
    const res = await axios({
        url: `${API}/users/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: user,
    })
    
    return res
} 

export const editEmployee = async (user: IUser, id: number) => {
    const updatedUser = {
        first_name: user.first_name,
        last_name: user.last_name,
        patronymic: user.patronymic,
        role: user.role,
        group: user.group,
        vacancy: user.vacancy,
    }

    const res = await axios({
        url: `${API}/users/${id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: updatedUser,
    })
    
    return res
} 

export const getEmployeeById = async (id: number) => {
    const res = await axios<any[]>({
        url: `${API}/users/${id}/`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const editSkills = async (skills: any, id: number) => {
    const skillsIds: any[] = [];
    skills.forEach((skill: any) => {
        skillsIds.push(skill.id);
    });

    const res = await axios({
        url: `${API}/users/${id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: {
            skill: skills
        },
    })
    
    return res
} 

export const getSkills = async (id: number) => {
    const res = await axios<any[]>({
        url: `${API}/users/${id}/`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const getCourses = async () => {
    const res = await axios<any[]>({
        url: `${API}/courses/`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const searchCourses = async (term: string) => {
    const res = await axios<any[]>({
        url: `${API}/courses/?search=${term}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const getStepikCourses = async () => {
    const res = await axios({
        url: `${API}/courses/?platform=Stepik`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })

    return res
}

export const deleteEmployee = async (id: number) => {
    const res = await axios({
        url: `${API}/users/${id}/`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const searchEmployees = async (term: string, companyId: number) => {
    const res = await axios({
        url: `${API}/users/?search=${term}&company__id=${companyId}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const searchEmployeesBySkill = async (term: string, companyId: number) => {
    const res = await axios({
        url: `${API}/users/?skill_only=true&search=${term}&company__id=${companyId}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const searchSkills = async (term: string, type: string) => {
    const res = await axios({
        url: `${API}/skills/?search=${term}&type=${type}&distinct=true&by_current_company=true`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const generateCareer = async () => {
    const res = await axios({
        url: `${API}/specializations/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const changeWorkExperience = async (id: number, work_experience: any) => {
    const res = await axios({
        url: `${API}/users/${id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: { work_experience },
    })
    
    return res
} 

export const changeEducation = async (id: number, educations: any) => {
    const res = await axios({
        url: `${API}/users/${id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: {
            education: educations
        },
    })
    
    return res
}


export const updateSkillConfirmation = async (skillId: number, confirmed: boolean) => {
    try {
        const res = await axios({
            url: `${API}/skills/${skillId}/`,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${access}`,
            },
            data: { confirmed }
        });
        return res;
    } catch (error) {
        console.error('Ошибка при обновлении статуса навыка:', error);
        throw error;
    }
};