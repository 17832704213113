import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '@/widgets';
import {
    Page,
    Board,
} from './styles';
import { useNavigate } from 'react-router-dom';

const categories = [
    {
        id: 1,
        title: 'Моя компания',
        links: [
            {
                id: 1,
                title: 'Сотрудники',
                to: '/dashboard-hr-employees',
            },
            {
                id: 2,
                title: 'Тесты',
                to: '/dashboard-hr-tests',
            },
            {
                id: 3,
                title: 'Структура',
                to: '/dashboard-hr-structure',
            },
            {
                id: 4,
                title: 'Аналитика',
                to: '/dashboard-hr-analitic',
            },
        ],
    },
    {
        id: 2,
        title: 'Другое',
        links: [
            {
                id: 5,
                title: 'База вакансий',
                to: '/dashboard-hr-vacancies',
            },
        ],
    },
];


export const HrLayout: React.FC = () => {
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (!user) {
          navigate('/');
        }
    
        if (!(user?.role === 'HR manager' || user?.role === 'Super HR manager')) {
          navigate('/');
        }
    }, [])

  return (
    <Page>
        <Navbar categories={categories} />
        <Board>
            <Outlet />
        </Board>
    </Page>
  )
}
