import React from 'react';
import {
  Wrapper,
  Container,
  Image,
  Info,
  Text,
  SuperHR,
} from './styles';
import avatar from '@/assets/images/avatar.jpg';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
} from '@chakra-ui/react';
import { DeleteUserPopup } from '../DeleteUserPopup/DeleteUserPopup';
import { EditUserPopup } from '../EditUserPopup/EditUserPopup';
import { IUser, ICompany } from '@/shared';

export interface HrCardProps {
  user: IUser;
  company: ICompany;
}

export const HrCard: React.FC<HrCardProps> = ({ user, company }) => {
  const { 
    isOpen: isEditUserPopupOpen,
    onOpen: onEditUserPopupOpen,
    onClose: onEditUserPopupClose
  } = useDisclosure();
  const { 
    isOpen: isDeleteUserPopupOpen,
    onOpen: onDeleteUserPopupOpen,
    onClose: onDeleteUserPopupClose
  } = useDisclosure();

  console.log(user);

  return (
    <>
      <Popover placement='bottom-end'>
        <PopoverTrigger>
          <Wrapper>
            <Container>
              <Image src={user?.image ? user?.image.image : avatar} alt="Васенева М.Е" />
              <Info>
                <h6>{user?.last_name} {user?.first_name[0]}.{user?.patronymic[0]}</h6>
                <Text>{user?.vacancy[0]?.title}</Text>
                {user?.role === 'Super HR manager' &&
                  <SuperHR>Super-hr</SuperHR>
                }
              </Info>
            </Container>
          </Wrapper>
        </PopoverTrigger>
        <PopoverContent width={'171px'} height={'58px'}>
          <PopoverBody>
            <button onClick={onEditUserPopupOpen}>Редактировать</button>
            <button
              style={{ color: 'var(--addable-red)' }}
              onClick={onDeleteUserPopupOpen}
            >
              Удалить сотрудника
            </button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <EditUserPopup user={user} company={company} isOpen={isEditUserPopupOpen} onClose={onEditUserPopupClose} />
      <DeleteUserPopup
        userId={user.id}
        isOpen={isDeleteUserPopupOpen}
        onClose={onDeleteUserPopupClose}
      />
    </>
  )
}
