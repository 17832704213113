import React from 'react';
import { Container, Text } from './styles';

export interface ISkillProps {
    title: string;
    confirmed?: boolean;
    children?: React.ReactNode;
}

export const Skill: React.FC<ISkillProps> = ({ title, confirmed, children }) => {
    return (
        <Container>
            <Text>{title}</Text>
            <>{children}</>
        </Container>
    )
}
