import { SkillsWrapper } from '@/entities/vacanci/ui/styles';
import { SkillList, SkillButton } from '@/features/employee/ui/EditSkillsPopup/EditSkillsPopup';
import { useDebounce, Skill } from '@/shared';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, InputGroup, InputLeftElement, Input, ModalFooter, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@/assets/icons/search.svg';
import { searchIntereses, editInterests, getInterests } from '@/features/user';

export interface EditInteresesPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EditInteresesPopup: React.FC<EditInteresesPopupProps> = ({ isOpen, onClose }) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const [intereses, setIntereses] = useState([]);
  const [searchedIntereses, setSearchedIntereses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchSoftTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    getInterests(user?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          console.log(res.data);
          setIntereses(res.data.interests);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  function handleSearch() {
    searchIntereses(debouncedSearchSoftTerm)
      .then((res: any) => {
        if (res?.status === 200) {
          setSearchedIntereses(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    handleSearch();
  }, [debouncedSearchSoftTerm])

  function handleSubmit(event: any) {
    event.preventDefault()
    editInterests(intereses, user?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          onClose();
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function addInteres(addedInteres: any) {
    let flag = true;
    intereses.forEach((s) => {
      if (s.name === addedInteres.name) {
        flag = false;
      } 
    })
    if (flag) {
      setIntereses([...intereses, addedInteres]);
    }
  } 

  function removeInteres(removedInteres: any) {
    const updatedIntereses = intereses.filter((interes: any) => {
      return interes?.id !== removedInteres?.id;
    })
    setIntereses(updatedIntereses);
  }
  
  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Редактирование интересов</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <div>
                <h5>Интересы</h5>
                <InputGroup padding={'6px'}>
                  <InputLeftElement pointerEvents='none'>
                    <SearchIcon
                      color='var(--addable-gray)'
                      width={18}
                      height={18}
                    />
                  </InputLeftElement>
                  <Input
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    color='messenger'
                    height={'28px'}
                    variant='flushed'
                    placeholder='Введите значение'
                  />
                </InputGroup>
                {searchTerm &&
                  <SkillsWrapper>
                  <SkillList>
                    {searchedIntereses && searchedIntereses.map((interes: any) =>
                      <Skill title={interes?.name} key={interes?.id}>
                        <SkillButton type='button' onClick={() => addInteres(interes)}>+</SkillButton>
                      </Skill>
                    )}
                    {searchedIntereses.length === 0 &&
                      <Skill title={'Новый'}>
                        <SkillButton type='button' onClick={() => {
                          addInteres({
                            id: Date.now(),
                            name: searchTerm,
                            type: "Hard"
                          })
                          setSearchTerm('')
                        }}>+</SkillButton>
                      </Skill>
                    }
                  </SkillList>
                  </SkillsWrapper>
                }
              </div>
              <SkillsWrapper>
              <SkillList>
                {intereses && intereses.map((interes: any) => 
                  <Skill title={interes.name} key={interes.id}>
                    <SkillButton type='button' onClick={() => removeInteres(interes)}>-</SkillButton>
                  </Skill>
                )}
              </SkillList>
              </SkillsWrapper>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                onClick={onClose}
                type='button'
                width='100%'
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
              >
                Сохранить изменения
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
}
