import React, { useState, useEffect } from 'react';
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
    Checkbox
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IUser, ICompany } from '@/shared';
import { editEmployee } from '@/features/employee';
import { roles } from '@/data/roles';
import { getEmployees } from '@/features/employee';
import employeesState from '@/pages/Employees/store/employeesState';
import { getCompany } from '@/entities/company/api';
import { getDepartmentByName } from '@/features/department';
import { getGroups } from '@/features/department';
import { getGroupByName } from '@/features/department';

interface IFormFileds {
  first_name: string;
  last_name: string;
  patronymic: string;
  birthday: string;
  email: string;
  company: any;
  department: string;
  specialization: any;
  role: string;
  group: any;
  vacancy: any;
  isSuperHR: boolean;
}

export interface EditUserPopupProps {
  isOpen: boolean;
  onClose: () => void;
  company: ICompany;
  user: IUser;
}

export const EditUserPopup: React.FC<EditUserPopupProps> = ({ isOpen, company, onClose, user }) => {
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const [userCompany, setUserCompany] = useState(company);

  const [role, setRole] = useState('');
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState<any>();
  const [groups, setGroups] = useState([]);
  const [departmentName, setDepartmentName] = useState('');
  const [groupName, setGroupName] = useState('');
  const [group, setGroup] = useState<any>();
  const [position, setPosition] = useState<any>();

  const currentUser = JSON.parse(localStorage.getItem('user'));
  const companyId = JSON.parse(
    localStorage.getItem('user')
  )?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  useEffect(() => {
    if (currentUser?.role === 'Super HR manager' || currentUser?.role === 'HR manager' && companyId) {
      getCompany(companyId)
        .then((res: any) => {
          setUserCompany(res.data);
          setDepartments(res.data.group);
        })
        .catch(e => {
          console.log(e);
        })
    }
  }, [])

  useEffect(() => {
    if (userCompany?.id) {
      getCompany(userCompany?.id)
      .then((res: any) => {
        setDepartments(res.data.group);
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }, [userCompany, company]);

  useEffect(() => {
    if (departmentName) {
      getDepartmentByName(departmentName)
      .then((res: any) => {
        if (res?.status === 200) {
          setDepartment(res.data[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }, [departmentName])

  useEffect(() => {
    if (department) {
      getGroups(department?.id)
        .then((res: any) => {
          if (res?.status === 200) {
            setGroups(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }, [department])

  useEffect(() => {
    getGroupByName(groupName)
    .then((res: any) => {
      if (res?.status === 200) {
        setGroup(res.data[0]);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }, [groupName])

  function handleGetEmployees() {
    getEmployees(userCompany?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          employeesState.setEmployees(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    data.company = [userCompany?.id];
    data.specialization = {title: data.specialization};

    if (role === 'Сотрудник') {
      data.role = 'User';
    }

    if (role === 'Hr-специалист') {
      data.role = 'HR manager';
    }

    // if (role === 'Super Hr-специалист') {
    //   data.role = 'Super HR manager';
    // }

    if (role === 'Руководитель') {
      data.role = 'Manager';
    }

    if (currentUser.role === 'Admin' && data.isSuperHR) {
      data.role = 'Super HR manager';
    }

    if (currentUser.role === 'Admin' && !data.isSuperHR) {
      data.role = 'HR manager';
    }

    data.group = group ? [group?.id] : [];
    data.vacancy = [Number(position)];

    console.log(data);
    editEmployee(data, user.id)
    .then((res: any) => {
      if (res?.status === 200) {
        handleGetEmployees();
        // window.location.reload();
        onClose();
      }
    })
    .catch((error: any) => {
      console.log(error);
    })
  }

  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Редактирование сотрудника</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <h5>Основная информация</h5>
              <FormControl isInvalid={Boolean(errors?.first_name)}>
                <FormLabel>Имя *</FormLabel>
                <Input
                  id='first_name'
                  {...register('first_name', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Мария'
                  defaultValue={user?.first_name}
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors?.first_name && <>{errors?.first_name.message}</>}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors?.last_name)}>
                <FormLabel>Фамилия *</FormLabel>
                <Input
                  id='last_name'
                  {...register('last_name', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Васенева'
                  defaultValue={user?.last_name}
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors?.last_name && <>{errors?.last_name.message}</>}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors?.patronymic)}>
                <FormLabel>Отчество *</FormLabel>
                <Input
                  id='patronymic'
                  {...register('patronymic', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Евгеньевна'
                  defaultValue={user?.patronymic}
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors?.patronymic && <>{errors?.patronymic.message}</>}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors?.email)}>
                <FormLabel>Почта *</FormLabel>
                <Input
                  disabled
                  id='email'
                  {...register('email')}
                  placeholder='vaseneva@emp.ru'
                  defaultValue={user?.email}
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors?.email && <>{errors?.email.message}</>}</FormErrorMessage>
              </FormControl>

              <h5>Работа и должность</h5>
              <FormControl isInvalid={Boolean(errors?.company)}>
                <FormLabel>Компания *</FormLabel>
                <Input
                  disabled
                  defaultValue={userCompany?.name}
                  id='company'
                  {...register('company')}
                  placeholder='Эмплифлоу'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors?.company && <>{errors?.company.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.department)}>
                <FormLabel>Департамент</FormLabel>
                <Select
                  isReadOnly={false}
                  variant='flushed'
                  placeholder='Выберите отдел'
                  value={departmentName}
                  onChange={e => setDepartmentName(e.target.value)}
                >
                  {departments.length > 0 ?
                    <>{departments.map(department =>
                      <option key={department.id}>{department?.name}</option>
                    )}</> :
                    <>
                      <option key={Date.now()}>Загрузка...</option>
                    </>
                  } 
                </Select>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.specialization)}>
                <FormLabel>Отдел</FormLabel>
                <Select
                  isReadOnly={false}
                  variant='flushed'
                  placeholder='Выберите отдел'
                  value={groupName}
                  onChange={e => setGroupName(e.target.value)}
                >
                  {groups.map(group =>
                    <option value={group.name} key={group.id}>{group.name}</option> 
                  )}
                </Select>
              </FormControl>

              <FormControl isInvalid={Boolean(errors?.role)}>
                <FormLabel>Должность *</FormLabel>
                  {currentUser?.role === 'Admin' &&
                    <Input
                      disabled
                      defaultValue='Hr-специалист'
                      id='role'
                      {...register('role')}
                      placeholder='Hr-специалист'
                      type='text'
                      size='sm'
                      variant='flushed'
                    />
                  }
                  {(currentUser?.role === 'HR manager' || currentUser?.role === 'Super HR manager') &&
                    <Select
                      value={position}
                      onChange={e => setPosition(e.target.value)}
                      isReadOnly={false}
                      variant='flushed'
                      placeholder='Выберите должность'
                    >
                      {userCompany?.company_positions?.map((position: any) =>
                        <option value={position.id} key={position.id}>{position.grade} {position.title}</option> 
                      )}
                    </Select>
                  }
                <FormErrorMessage>{errors?.role && <>{errors?.role.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.specialization)}>
                <FormLabel>Роль *</FormLabel>
                  {(currentUser?.role === 'Super HR manager') &&
                    <Select
                      value={role}
                      onChange={e => setRole(e.target.value)}
                      isReadOnly={false}
                      variant='flushed'
                      placeholder='Выберите роль'
                    >
                      {roles.map(role =>
                        <option value={role.title} key={role.title}>{role.title}</option>
                      )}
                    </Select>
                  }
                  {(currentUser?.role === 'HR manager') &&
                    <Select
                      value={role}
                      onChange={e => setRole(e.target.value)}
                      isReadOnly={false}
                      variant='flushed'
                      placeholder='Выберите роль'
                    >
                      {roles.map(role =>
                        <option
                          disabled={role.title === 'Super HR-специалист' ? true : false}
                          value={role.title}
                          key={role.title}
                        >
                          {role.title}
                        </option> 
                      )}
                    </Select>
                  }
                  {currentUser?.role === 'Admin' &&
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                      <Input
                        disabled
                        defaultValue='Hr-специалист'
                        id='role'
                        {...register('role')}
                        placeholder='Hr-специалист'
                        type='text'
                        size='sm'
                        variant='flushed'
                      />
                      <Checkbox
                        id='isSuperHR'
                        {...register('isSuperHR')}
                        size='lg'
                      >
                        <p>Супер-HR</p>
                      </Checkbox>
                    </div>
                  }
              </FormControl>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
                isLoading={isSubmitting ? true : false}
              >
                Сохранить изменения
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
}