import React, { useState, useEffect } from 'react';
import {
  Tr,
  Td,
  Progress,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Image,
  Text,
  PopoverButton,
  Manager,
} from './styles';
import { IUser } from '@/shared';
import avatar from '@/assets/images/avatar.jpg';
import DotsIcon from '@/assets/icons/dots.svg';
import { getGroupById } from '@/features/department';
import { AppointProjectPopup, AppointTaskPopup } from '@/features/projects';
import { getCreatedProjects } from '@/entities/projects';
import { Link } from 'react-router-dom';
import projectsPageState from '@/pages/Projects/store/projectsPageState';
import { observer } from 'mobx-react-lite';
import { getCreatedTasks } from '@/entities/tasks';

export interface RowProps {
  employee: IUser;
  handleEdit: (employee: IUser) => void;
  handleDelete: (employee: IUser) => void;
}

export const Row: React.FC<RowProps> = observer(({ employee, handleEdit, handleDelete }) => {
  const { isOpen: isAppointProjectOpen, onOpen: onAppointProjectOpen, onClose: onAppointProjectClose } = useDisclosure();
  const { isOpen: isAppointTaskOpen, onOpen: onAppointTaskOpen, onClose: onAppointTaskClose } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [departName, setDepartName] = useState();
  const [groupName, setGroupName] = useState();
  const groupId = employee.group[employee.group.length - 1];
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState(projectsPageState.tasks);

  const userId = JSON.parse(localStorage.getItem('user'))?.id; 
  const user = JSON.parse(localStorage.getItem('user')); 

  useEffect(() => {
    if (groupId) {
      getGroupById(groupId)
        .then((res) => {
          setGroupName(res.data.name);

          getGroupById(res.data.parent)
            .then((res) => {
              setDepartName(res.data.name);
            })
            .catch((e) => {
              console.log(e);
            })
        })
        .catch((e) => {
          console.log(e);
        })
    }
  
  }, [])

  useEffect(() => {
    getCreatedProjects(userId)
      .then((items) => {
        setProjects(items);
      })
      .catch(e => {
        console.log(e);
      })

    if (tasks.length === 0) {
      getCreatedTasks(userId)
        .then((items) => {
          setTasks(items);
        })
        .catch((e) => {
          console.log(e);
        })
    } 
  }, [])

  return (
    <>
      <Tr key={employee?.id}>
        <Td>
          <Link
          to={
            user?.role === 'Manager' ?
            `/dashboard-manager-profile?search=${employee.id}` :
            `/dashboard-hr-profile?search=${employee.id}`
          }
          style={{ fontWeight: '400' }}
        >
          <div
              style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
          >
            <Image src={employee?.image ? employee?.image.image : avatar} alt="avatar" />
              <h6>{employee?.last_name} {' '} {employee?.first_name} {' '} {employee?.patronymic}</h6>
          </div>
        </Link>
        </Td>
        <Td>
            {employee?.age ? <Text>{employee.age}</Text> : <Text>-</Text>}
        </Td>
        <Td>
            <Text>{employee?.vacancy[0]?.grade} {employee?.vacancy[0]?.title ? employee?.vacancy[0]?.title : <>-</>}</Text>
        </Td>
        <Td>
            <Text>{employee.role === 'Manager' ? <Manager>Руководитель</Manager> : <>-</>}</Text>
        </Td>
        <Td>
            {employee?.specialization ? <Text>{employee.specialization?.title}</Text> : <Text>-</Text> }
        </Td>
        {/* <Td display={'flex'} alignItems={'center'} gap={'6px'}>
            <Text>23%</Text>
            <Progress
                background='var(--addable-gray)'
                borderRadius={'100px'}
                color='var(--main-purple)'
                value={23}
                height='4px'
                width={'60px'}
            />
        </Td> */}
        <Td>
          <Text>{groupName ? groupName : <>-</>}</Text>
        </Td>
        <Td>
            <Text>{departName ? departName : <>-</>}</Text>
        </Td>
        <Td>
            <div>
            <Popover placement='bottom-end'>
                <PopoverTrigger>
                    <button><DotsIcon color='var(--main-purple)'/></button>
                </PopoverTrigger>
                <PopoverContent width="full">
                    <PopoverBody>
                      {user?.role === 'Manager' &&
                        <>
                        {projects.length > 0 ?
                        <PopoverButton
                          onClick={onAppointProjectOpen}
                        >
                          Назначить на проект
                        </PopoverButton> :
                        <PopoverButton>Загрузка...</PopoverButton>
                        }
                        {tasks.length > 0 ?
                        <PopoverButton
                          onClick={onAppointTaskOpen}
                        >
                          Назначить на задачу
                        </PopoverButton> :
                        <PopoverButton>Загрузка...</PopoverButton>
                        }
                        </>
                      }
                      {user?.role !== 'Manager' && 
                        <PopoverButton
                            onClick={() => handleEdit(employee)}
                        >
                            Редактировать профиль
                        </PopoverButton>
                      }
                        {
                        (
                          employee?.role === 'User' ||
                          employee?.role === 'Manager' &&
                          (user?.role === 'HR manager' || user?.role === 'Super HR manager')
                        ) && 
                        <>
                        {(user?.role === 'HR manager' || user?.role === 'Super HR manager') &&
                          <PopoverButton>
                            <Link to={`/dashboard-hr-profile?search=${employee.id}`} style={{ fontWeight: '400' }}>
                              Перейти на страницу сотрудника
                            </Link>
                          </PopoverButton>
                        }
                        {user?.role === 'Manager' &&
                          <PopoverButton>
                            <Link to={`/dashboard-manager-profile?search=${employee.id}`} style={{ fontWeight: '400' }}>
                              Перейти на страницу сотрудника
                            </Link>
                          </PopoverButton>
                        }
                        </>}
                        {user?.role !== 'Manager' && 
                          <PopoverButton
                              style={{ color: 'var(--addable-red)' }}
                              onClick={() => handleDelete(employee)}
                          >
                              Удалить сотрудника
                          </PopoverButton>
                        }
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            </div>
        </Td>
      </Tr>
      <AppointProjectPopup isOpen={isAppointProjectOpen} onClose={onAppointProjectClose} projects={projects} employee={employee}/>
      <AppointTaskPopup isOpen={isAppointTaskOpen} onClose={onAppointTaskClose} tasks={tasks} employee={employee}/>
    </>
  )
});
