import React, { useEffect, useState } from 'react';
import {
  Card,
  Top,
  Left,
  Right,
  PopoverButton,
  HrList,
  AddButton,
} from './styles';
import DotsIcon from '@/assets/icons/dots.svg';
import CollapseIcon from '@/assets/icons/collapse.svg';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ICompany } from '@/shared';
import { EmployeesList } from '@/widgets';
import {
  editCompany,
  deleteCompany,
} from '@/features/company';
import { getCompanies } from '@/entities/company';
import companiesState from '@/entities/company';
import { getHrEmployees } from '@/features/employee/api';
import { AddHrPopup } from '@/entities/user/ui/AddHrPopup/AddHrPopup';
import AddHrIcon from '@/assets/icons/add-hr.svg';

interface IFormFileds {
  name: string;
  subscription_terms: string;
  INN: string;
  OGRN: string;
  city: string;
}

export interface CompanyCardProps {
  company: ICompany;
}

export const CompanyCard: React.FC<CompanyCardProps> = ({ company }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAddHrOpen, onOpen: onAddHrOpen, onClose: onAddHrClose } = useDisclosure();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [users, setUsers] = useState([]);

  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  function handleGetCompanies() {
    getCompanies()
    .then((res: any) => {
      if (res?.status === 200) {
        companiesState.setCompanies(res.data);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    editCompany(company.id, data)
      .then((res: any) => {
        if (res?.status === 200) {
          handleGetCompanies();
          onClose();
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleDeleteCompany() {
    deleteCompany(company.id)
    .then((res: any) => {
      if (res?.status === 204) {
        handleGetCompanies();
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  function handleGetHrEmployees() {
    getHrEmployees(company.name)
      .then((res: any) => {
        if (res?.status === 200) {
          setUsers(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    if (!isCollapsed) {
      handleGetHrEmployees();
    } 
  }, [isCollapsed])

  return (
    <Card>
      <Top>
        <Left>
          <h5>{company.name}</h5>
          {/* {company.count_users > 0 ?
            <p>{company.count_users} hr сотрудника</p> :
            <p>сотрудники не добавлены</p>
          } */}
        </Left>
        <Right>
          <Popover placement='bottom-end'>
            <PopoverTrigger>
              <button><DotsIcon/></button>
            </PopoverTrigger>
            <PopoverContent width={'171px'} height={'58px'}>
              <PopoverBody>
                <PopoverButton onClick={onOpen}>Редактировать</PopoverButton>
                <PopoverButton
                  onClick={handleDeleteCompany}
                  style={{ color: 'var(--addable-red)' }}
                >
                  Удалить компанию
                </PopoverButton>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <button onClick={() => setIsCollapsed(!isCollapsed)}>

            <CollapseIcon style={{ transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out' }}/>
          </button>
        </Right>
      </Top>
      {!isCollapsed &&
        <>
          <HrList>
            <AddButton
              onClick={onAddHrOpen}
            >
              <AddHrIcon
                width={48}
                height={48}
              />
              <h6 style={{ color: 'var(--main-purple)' }}>HR-сотрудник</h6>
            </AddButton>
            <EmployeesList company={company} users={users}/>
          </HrList>
          <AddHrPopup company={company} isOpen={isAddHrOpen} onClose={onAddHrClose}/>
        </>
      }

        <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalContent borderRadius={16}>
              <ModalHeader alignItems={'center'}>
                <h3 style={{ textAlign: 'center' }}>Редактирование компании</h3>
              </ModalHeader>
              <ModalCloseButton top={'14px'} />
              <ModalBody
                padding='20px'
                display='flex'
                flexDirection='column'
                gap='30px'
                borderTop='1px solid var(--addable-gray)'
                borderBottom='1px solid var(--addable-gray)'
              >
                <FormControl isInvalid={Boolean(errors.name)}>
                  <FormLabel>Название компании *</FormLabel>
                  <Input
                    id='name'
                    defaultValue={company?.name && company.name}
                    {...register('name', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='Компания 5'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.name && <>{errors.name.message}</>}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.subscription_terms)}>
                  <FormLabel>Условия подписки *</FormLabel>
                  <Input
                    id='subscription_terms'
                    defaultValue={company?.subscription_terms && company.subscription_terms}
                    {...register('subscription_terms', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='Опишите условия подписки'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.subscription_terms && <>{errors.subscription_terms.message}</>}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.INN)}>
                  <FormLabel>ИНН *</FormLabel>
                  <Input
                    id='INN'
                    defaultValue={company?.INN && company.INN}
                    {...register('INN', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='000-000-000-00'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.INN && <>{errors.INN.message}</>}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.OGRN)}>
                  <FormLabel>ОГРН *</FormLabel>
                  <Input
                    id='OGRN'
                    defaultValue={company?.OGRN && company.OGRN}
                    {...register('OGRN', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='000-000-000-00'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.OGRN && <>{errors.OGRN.message}</>}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.city)}>
                  <FormLabel>Город *</FormLabel>
                  <Input
                    id='city'
                    defaultValue={company?.city && company.city}
                    {...register('city', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='Москва'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.city && <>{errors.city.message}</>}</FormErrorMessage>
                </FormControl>
              </ModalBody>

              <ModalFooter gap='8px' display='flex'>
                <Button
                  type='button'
                  width='100%'
                  onClick={onClose}
                  variant='outline'
                  color='var(--main-purple)'
                  borderRadius='8px'
                >
                  Отмена
                </Button>
                <Button
                  type='submit'
                  width='100%'
                  borderRadius='8px'
                  bg='var(--main-purple)'
                  _hover={{ bg: 'var(--hover-purple)' }}
                  color='#fff'
                  isLoading={isSubmitting ? true : false}
                >
                  Сохранить изменения
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
    </Card>
  )
}
