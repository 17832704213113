import React, { useState, useEffect } from 'react';
import {
  Board,
  TopBar,
  ButtonWrapper,
} from './styles';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Button 
} from '@chakra-ui/react';
import AddIcon from '@/assets/icons/add.svg';
import SearchIcon from '@/assets/icons/search.svg';
import { CompaniesList } from '@/widgets/CompaniesList/CompaniesList';
import { AddCompanyPopup } from '@/entities/company/ui/AddCompanyPopup/AddCompanyPopup';
import {
  useDisclosure,
} from '@chakra-ui/react';
import { getCompanies } from '@/entities/company';
import companiesState from '@/entities/company';
import { observer } from 'mobx-react-lite';
import { searchCompany } from '@/features/company';
import { useDebounce } from '@/shared';

export const Admin: React.FC = observer(() => {
  const user = JSON.parse(localStorage.getItem('user'));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getCompanies()
    .then((res: any) => {
      if (res?.status === 200) {
        companiesState.setCompanies(res.data);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }, [])

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  function handleSearch() {
    searchCompany(debouncedSearchTerm)
    .then((res: any) => {
      if (res?.status === 200) {
        companiesState.setCompanies(res.data);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (typeof debouncedSearchTerm === 'string') {
      handleSearch();
    }
  }, [debouncedSearchTerm]);

  if (user) {
    return (
      <>
        <>
          <Board>
            <TopBar>
              <InputGroup padding={'6px'}>
                <InputLeftElement pointerEvents='none'>
                  <SearchIcon
                    color='var(--addable-gray)'
                    width={18}
                    height={18}
                  />
                </InputLeftElement>
                <Input
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  color='messenger'
                  height={'28px'}
                  variant='flushed'
                  placeholder='Поиск по компаниям'
                />
              </InputGroup>
              <ButtonWrapper>
                <Button
                  onClick={onOpen}
                  borderRadius='8px'
                  bg='var(--main-purple)'
                  _hover={{ bg: 'var(--hover-purple)' }}
                  leftIcon={<AddIcon color='#fff' />} 
                >
                  <h6 style={{ color: '#fff' }}>Добавить компанию</h6>
                </Button>
              </ButtonWrapper>
            </TopBar>
            <CompaniesList companies={companiesState.companies}/>
          </Board>
        </>
        <AddCompanyPopup isOpen={isOpen} onClose={onClose}/>
      </>
    )
  }
});
